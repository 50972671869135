import React from 'react';
import { CListGroupItem, CRow, CCol } from "@coreui/react";
import './NavItem.css';

const NavItem = (props) => {
  return (
    <CListGroupItem
      className="navItem"
      onClick={props.handleClick}
      exact={props.exact}
      tag={props.tag}
      to={props.to}
    >
      {/* attach ClassName on CLick */}
      <CRow>
        <CCol md={{size: 7, offset: 3}}>
          <span className="navItemContent">{props.text}</span>
        </CCol>
      </CRow>
    </CListGroupItem>
  );
}

export default NavItem;