import React from 'react';
import { CRow, CCol, CForm } from '@coreui/react';
import Category from './category/Category';
import '../Filter.css'

const Categories = (props) => {

  return (
    <CRow className="filterItem">
      <CCol className="text-left" md={{ size: 7, offset: 4 }}>
        <p>&nbsp;&nbsp;Categories</p>
      </CCol>
      <CCol className="text-left" md={{ size: 7, offset: 5 }}>
        <CForm>
          {categories.map((cat) => (
            <Category handleCheck={props.handleCat} catName={cat} key={cat} />
          ))}
        </CForm>
      </CCol>
      {/* <p onClick={() => {props.handleCats("cat1")}}>
        &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;Cat1
      </p>
      <p>
        &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;Cat2
      </p>
      <p>
        &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;Cat3
      </p> */}
    </CRow>
  );
}

const categories = ["Comparisons", "Dating", "Mental Health", "People", "Politics", "Sociology", ]

export default Categories;