import React, { useState, useEffect, useContext } from 'react';
import { CCollapse, CContainer } from '@coreui/react';
import { store } from '../../context/store';
import Categories from './categories/Categories';
import PublishDate from './publish-date/PublishDate';
import './Filter.css';

const FilterContainer = (props) => {

  const [fromDate, setFromDate] = useState();
  const [toDate, setToDate] = useState(new Date());

  const globalState = useContext(store);
  const { state, dispatch } = globalState;
  console.log("state in FilterContainer: ", state);

  // you have options, perform the filter here
  //    1. get the categories
  //    2. do the filter on the articles
  //    3. pass the article data to the store in the dispatch
  // or
  //    1. get the categories
  //    2. pass back to store in the dispatch
  //    3. do the filter in a method executed by the store
  const getCat = (cat, isCatSelected) => {
    console.log(cat);
    console.log(isCatSelected);
    dispatch({type: "filterTitlesByCat", cat, isCatSelected});
  }

  const filterByTitle = () => {
    if (fromDate !== undefined) {
      dispatch({type: "filterTitlesByDate", fromDate, toDate});
    }
  }

  useEffect(filterByTitle, [fromDate, toDate]);

  // const getDateRange = () => {
  //   console.log("fromDate in filterContainer: ", fromDate);
  //   console.log("toDate in filterContainer: ", toDate);
  // }

  return (
    <CCollapse show={props.collapse}>
      <CContainer id="filter">
        <Categories handleCat={getCat} />
        <PublishDate 
          handleFromDate={setFromDate} 
          handleToDate={setToDate} 
          fromDate={fromDate} 
          toDate={toDate}
        />
      </CContainer>
    </CCollapse>
  );
}

export default FilterContainer;