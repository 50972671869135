import retrieveMetadata from "./metadata-retrieval-service";

let cats = [];
/*
  initialize fromDate as the begninning of unix epoch
    and toDate
    so that category filter can happen without fromDate selected
*/
let fromDate = new Date(0);
let toDate = new Date();

function getTitles() {
  if (this.metadata.length > 0) {
    return this.metadata.map((metadata) => metadata.title);
  }
  const articleMetadata = retrieveMetadata();
  this.metadata = articleMetadata;
  return this.metadata.map((metadata) => metadata.title);
}

function filterByCat(currentFilteredTitles, cat, isCatSelected) {
  if (isCatSelected) {
    cats.push(cat);
  } else {
    const index = cats.indexOf(cat);
    if (index > -1) {
      cats.splice(index, 1);
    }
  }

  return filterTitles(this.metadata, currentFilteredTitles);
}

function filterByDate(currentFilteredTitles, selectedFromDate, selectedToDate) {
  if (selectedToDate < selectedFromDate) {
    return currentFilteredTitles;
  }

  fromDate = selectedFromDate;
  toDate = selectedToDate;

  return filterTitles(this.metadata, currentFilteredTitles);
}

const filterTitles = (metadata, currentFilteredTitles) => {
  let filteredTitles = metadata;
  if (
    !metadata.every((metadata) =>
      currentFilteredTitles.includes(metadata.title)
    )
  ) {
    // console.log("in the currentFilter filter", currentFilteredTitles);
    filteredTitles.filter((metadata) =>
      currentFilteredTitles.includes(metadata.title)
    );
  }
  return filteredTitles
    .filter((metadata) => theFilter(metadata))
    .map((metadata) => metadata.title);
};

const theFilter = (metadata) => {
  return (
    cats.every((cat) => metadata.categories.includes(cat)) &&
    convertToComparableDate(metadata.title, metadata.publishDate) >=
      fromDate.getTime() &&
    convertToComparableDate(metadata.title, metadata.publishDate) <=
      toDate.getTime()
  );
};

// TODO: remove title, it's only for logging purposes
const convertToComparableDate = (title, isoDate) => {
  let date = new Date(Date.parse(isoDate));
  date = new Date(date.getUTCFullYear(), date.getUTCMonth(), date.getUTCDate());
  // console.log(title, date.getTime());
  // console.log("fromDate: ", fromDate.getTime());
  // console.log("toDate: ", toDate.getTime());
  return date.getTime();
};

// object literal for title manipulations
const metadata = {
  metadata: [],
  getTitles,
  filterByCat,
  filterByDate,
};

export default metadata;
