import React from 'react';
import { CCol, CRow } from '@coreui/react';
import DatePicker from 'react-datepicker';
import '../Filter.css';
import './PublishDate.css';

const PublishDate = (props) => {

  const handleDateSelect = async (setDate, date) => {
    setDate(date);
    //passDatesToFilter(date);
  }

  return (
    <CRow id="publishDateContainer">
      <CCol className="text-left" md={{size: 7, offset: 4}}>
        <p className="filterItem">
          &nbsp;&nbsp;PublishDate
        </p>
      </CCol>
      <CCol md={{size: 5, offset: 5}}>
        <div className="customDatePickerGroup">
          <form className="form-inline">
            <label className="datePickerLabel" htmlFor="fromDate">From</label>
            <DatePicker selected={props.fromDate} onChange={date => handleDateSelect(props.handleFromDate, date)} name="fromDate"/>
          </form>
          <form className="form-inline">
            <label className="datePickerLabel" htmlFor="toDate">To</label>
            <DatePicker selected={props.toDate} onChange={date => handleDateSelect(props.handleToDate, date)} name="toDate"/>
          </form>
        </div>
      </CCol>
    </CRow>
  );
}

export default PublishDate;