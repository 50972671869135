import React from 'react';
import { Switch, Route } from 'react-router-dom';
import Article from '../body/Article';
import Archive from '../body/Archive';
import Contact from '../body/Contact';

const ContentContainer = () => {
  return (
    <Switch>
      <Route exact path="/">
        <Article />
      </Route>
      <Route path="/archive">
        <Archive />
      </Route>
      <Route path="/contact">
        <Contact />
      </Route>
    </Switch>
  );
}

export default ContentContainer;