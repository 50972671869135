import { useState, useCallback } from 'react';

export default function useToggle(initialValue = false) {
  const [val, setVal] = useState(initialValue);

  const toggle = useCallback(() => {
    setVal(v => !v);
  }, []);

  return [val, toggle];
}