import React, { createContext, useReducer } from 'react';
import metadata from './titles/titles.js';

const initialState = {
  titles: metadata.getTitles()
};
const store = createContext(initialState);
const { Provider } = store;

const reducer = (state, action) => {
  switch(action.type) {
    case 'filterTitlesByCat':
      return {
        titles: metadata.filterByCat(state.titles, action.cat, action.isCatSelected)
      }
    case 'filterTitlesByDate':
      return {
        titles: metadata.filterByDate(state.titles, action.fromDate, action.toDate)
      }
    default: 
      return initialState;
  };
}

const StateProvider = ({children}) => {
  const [state, dispatch] = useReducer(reducer, initialState);

  return <Provider value={{ state, dispatch }}>{children}</Provider>
}

export { store, StateProvider }