import React from 'react';
import { 
  CContainer, 
  CRow, 
  CCol,
  CForm,
  CFormGroup,
  CLabel,
  CInput,
  CTextarea,
  CButton
} from '@coreui/react';

const Contact = () => {
  return (
    <CContainer>
      <CRow>
        <CCol>
          <CForm style={{ textAlign:"left" }}>
            <CFormGroup>
              <CLabel htmlFor="email">
                Email
              </CLabel>
              <CInput
                type="email"
                id="email"
                name="email"
                placeholder="Please provide your email address"
              />
            </CFormGroup>
            <CFormGroup>
              <CLabel htmlFor="message">
                Message
              </CLabel>
              <CTextarea 
                id="message"
                name="message"
                placeholder="Let me know your thoughts!"
              />
            </CFormGroup>
            <CButton color={"info"} >
              Send
            </CButton>
          </CForm>
        </CCol>
      </CRow>
    </CContainer>
  );
}

export default Contact;