const metadata = [
  {
    title: "Social Media and the Loss of Connection",
    categories: ["Sociology", "People", "Mental Health"],
    publishDate: "2020-10-29T02:44:08.934+00:00"
  },
  {
    title: "Why Does Online Dating Suck?",
    categories: ["Comparisons", "Dating", "People"],
    publishDate: "2020-10-28T02:44:08.934+00:00"
  },
  {
    title: "Online Dating is Awesome, and Probably Doesn't Suck",
    categories: ["Comparisons", "Dating", "People"],
    publishDate: "2020-10-27T02:44:08.934+00:00"
  },
  {
    title: "The Government Totally Sucks",
    categories: ["Comparisons", "Politics"],
    publishDate: "2020-10-24T02:44:08.934+00:00"
  },
  {
    title: "The Government Absolutely Does Not Suck",
    categories: ["Comparisons", "Politics"],
    publishDate: "2020-07-07T02:44:08.934+00:00"
  },
  {
    title: "Game of Thrones is Completely Dead to Me and My Soul has been Demented",
    categories: ["Sociology"],
    publishDate: "2020-06-16T02:44:08.934+00:00"
  },
  {
    title: "Is the World Screwed? Should We Move to Mars?",
    categories: ["People", "Mental Health"],
    publishDate: "2020-06-10T02:44:08.934+00:00"
  },
  {
    title: "Cinch Up Those Bootstraps! You Should Be More Positive!!!!!!!",
    categories: ["Comparisons", "Mental Health"],
    publishDate: "2020-06-01T02:44:08.934+00:00"
  },
  {
    title: "life sucks. let negativity consume your soul.",
    categories: ["Comparisons", "Mental Health"],
    publishDate: "2020-05-24T02:44:08.934+00:00"
  }
]

module.exports = {
  metadata
}