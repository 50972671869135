import React, { useContext } from 'react';
import { CListGroup, CListGroupItem } from '@coreui/react';
import { store } from '../../../context/store';
import './Archive.css';

const Archive = () => {
  const globalState = useContext(store);
  const { state } = globalState;

  return (
    <CListGroup>
      {state.titles.map(title => (
        <CListGroupItem key={title}>
          {title}
        </CListGroupItem>
      ))}
    </CListGroup>
  )
}

export default Archive;