import React from 'react';
import { CFormGroup, CLabel, CInputCheckbox } from '@coreui/react';

const Category = (props) => {
  return (
    <CFormGroup className="d-block text-left" variant="checkbox" inline={true}>
      <CLabel className="d-block">
        <CInputCheckbox 
          onChange={(e) => {props.handleCheck(e.target.name, e.target.checked)}} 
          className="position-relative" 
          name={props.catName} 
        />
        {props.catName}
      </CLabel>
    </CFormGroup>
  );
}

export default Category;