import React from 'react';
import { CContainer, CRow, CCol } from '@coreui/react';
import { BrowserRouter as Router } from 'react-router-dom';
import NavPane from '../nav/NavPane';
import ContentContainer from '../content/container/ContentContainer.js';
import useWindowDimensions from './useWindowDimensions';
import useToggle from './useToggle';
import menuIcon from '../../resources/menu-24px.svg';
import './App.css';

const App = () => {
  const { width } = useWindowDimensions();
  const isDesktop = width <= 768 ? false : true;
  const [ isNavbarVisible, toggleisNavbarVisible ] = useToggle(isDesktop);

  return (
    <Router>
      <div className="App d-flex">
        <CContainer className="h-100" id="appContainer" fluid={true}>
          <CRow className="h-100">
            <CCol md="10" sm="10" xs="10" id="header"></CCol>
            <Hamburger handleToggle={toggleisNavbarVisible} width={width} />
          </CRow>
          <CRow>
            <NavPane isVisible={isNavbarVisible} />
            <CCol xl={{ size: 7, offset: 3 }} lg={{ size: 7, offset: 4 }} md={{ size: 7, offset: 5 }} id="contentBody">
              {/* <p>
              {ipsum}
            </p> */}
              <ContentContainer />
            </CCol>
            <CCol md="0" sm="0" xs="0" id="right-gutter"></CCol>
          </CRow>
          <CRow md={{ size: 6, offset: 3 }} id="footer"></CRow>
        </CContainer>
      </div>
    </Router>
  );
}

const Hamburger = (props) => {
  const visibility = props.width <= 768 ? "visible" : "invisible";

  return ( 
    <CCol className={`${visibility} d-flex justify-content-center align-items-center`} md="2" sm="2" xs="2" id="hamburger">
      <img src={menuIcon} alt="Menu Icon" onClick={props.handleToggle} /> 
    </CCol> 
  )
};

export default App;