import React, { useState } from 'react';
import { CCol, CListGroup } from "@coreui/react";
import { NavLink } from 'react-router-dom';
import NavItem from './NavItem';
import FilterContainer from '../filter/FilterContainer';
import './NavPane.css';

const NavPane = (props) => {

  // TODO: convert to useToggle
  const [collapse, setCollapse] = useState(false);

  const toggleCollapse = () => {
    setCollapse(!collapse);
  }

  let style;

  if (props.isVisible) {
    style = {
      marginLeft: "0"
    }
  } else {
    style = {
      marginLeft: "-100vh"
    }
  }

  return (
    // <CCol md="3" className="position-fixed" id="navbar">
    <CCol className="h-100 position-absolute" id="navbar" xl="3" lg="4" md="5" sm="6" xs="6" style={style} >
      <CListGroup id="navGroup">
        <NavItem exact={true} tag={NavLink} to={'/'} text={'Latest'} />
        <NavItem handleClick={toggleCollapse} exact={false} tag={NavLink} to={'/archive'} text={'Archive'} />
        <FilterContainer collapse={collapse}/>
        <NavItem exact={false} tag={NavLink} to={'/contact'} text={'Contact'} />
      </CListGroup>
    </CCol>
  );
}

// for information in future.....didn't use HOC here becuase HOC is the following
//      takes a component and performs extra juice on it
//      in my case I was trying to pass a component to a component for the purpose of composing 
//      two components together......so why use an HOC for this when I could just use composition?

export default NavPane;